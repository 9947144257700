import { Component } from 'vue-property-decorator'
import { mapState } from 'vuex'
import GtrSuper from '@/modules/common/components/mixins/gtr-super.mixin'
import Container from 'typedi'
import Notification from '@/modules/common/services/notification.service'
import ErrorHandlerService from '@/modules/common/services/error-handler.service'

@Component({
  name: 'GtrEventGroupView',
  computed: {
    ...mapState('registration', ['participantsGroup', 'groupFees']),
    ...mapState('option', ['option_groups'])
  }
})

export default class GtrEventGroupView extends GtrSuper {
  option_groups!: Record<string, any>
  participantsGroup!: Record<string, any>
  groupFees!: Record<string, any>

  data () {
    return {
      loading: false,
      groupMembers: [],
      showGroupModal: false,
      newGroupName: null
    }
  }

  async mounted () {
    await this.fetchGroupData()
    await this.fetchGroupFees()
    await this.fetchOptionGroups()
  }

  async fetchGroupData () {
    try {
      this.$data.loading = true
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        group_uuid: this.$route.params.group_uuid
      }
      const response = await this.$store.dispatch('registration/getParticipantsGroup', payload)
      // this.$data.groupMembers = response.data.participant_group_participants
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async fetchGroupFees () {
    try {
      this.$data.loading = true
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        group_uuid: this.$route.params.group_uuid
      }
      const response = await this.$store.dispatch('registration/getGroupFees', payload)
      this.$data.groupMembers = response.data.group_all
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  async fetchOptionGroups () {
    try {
      this.$data.loading = true
      await this.$store.dispatch('option/getOptionsGroup', { event_uuid: this.$route.params.event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }

  handleGoToParticipantRecord (uuid: string) {
    const routeData = this.$router.resolve({
      name: 'level-two.event.attendees.edit',
      params: {
        event_uuid: this.$route.params.event_uuid,
        attendee_uuid: uuid
      }
    })
    window.open(routeData.href, '_blank')
  }

  openEditGroupModal () {
    this.$data.showGroupModal = true
  }

  closeEditGroupModal () {
    this.$data.showGroupModal = false
  }

  async editGroupName () {
    try {
      const payload = {
        event_uuid: this.$route.params.event_uuid,
        group_uuid: this.$route.params.group_uuid,
        data: {
          group_name: this.$data.newGroupName
        }
      }
      await this.$store.dispatch('registration/updateParticipantsGroup', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.newGroupName = null
      this.closeEditGroupModal()
    }
  }

  formatToCurrency (num: number) {
    return this.$options?.filters?.toCurrency(num)
  }

  get groupLeader () {
    if (this.$data.groupMembers?.length) {
      let leader = {}
      for (let i = 0; i < this.$data.groupMembers.length; i++) {
        if (this.$data.groupMembers[i].participant_group.parent) {
          leader = this.$data.groupMembers[i]
        }
      }
      return leader
    }
    return {}
  }

  get groupName (): string {
    if (this.participantsGroup && this.participantsGroup.group_name) {
      return this.participantsGroup.group_name
    } else {
      const leader: Record<string, any> = this.groupLeader
      if (leader) {
        return `${leader.participant_data?.full_name}'s`
      }
    }
    return ''
  }

  get groupDetails (): string {
    const leader: Record<string, any> = this.groupLeader
    const groupMembers = this.sortedGroupMembers
    const otherOrOthers = groupMembers.length - 1 === 1 ? 'other' : 'others'
    if (leader && groupMembers) {
      return `${leader.participant_data?.full_name} and ${(groupMembers.length - 1).toString()} ${otherOrOthers}`
    }
    return ''
  }

  get groupTotal (): number {
    return this.groupFees ? this.groupFees.balance : -1
  }

  get sortedGroupMembers () {
    if (this.$data.groupMembers?.length) {
      const group = [...this.$data.groupMembers]
      const sortedGroup: any[] = []
      let leader = null
      for (let i = 0; i < group.length; i++) {
        if (group[i].participant_group?.parent && group[i].participant_data) {
          leader = group[i]
        } else if (!group[i].participant_group?.parent && group[i].participant_data) {
          sortedGroup.push(group[i])
        }
      }
      if (leader) {
        sortedGroup.unshift(leader)
      }
      return sortedGroup
    }
    return []
  }

  getMemberUuid (member: Record<string, any>): string {
    return member?.participant_data?.uuid
  }

  getMemberFullName (member: Record<string, any>): string {
    return member?.participant_data?.full_name
  }

  getMemberRegType (member: Record<string, any>): string {
    if (this.option_groups && member?.registration_type) {
      let regType = ''
      for (let i = 0; i < this.option_groups.length; i++) {
        const optionGroup = this.option_groups[i]
        if (optionGroup.name === 'Registration Types') {
          for (let j = 0; j < optionGroup.options.length; j++) {
            const option = optionGroup.options[j]
            if (option.uuid === member.registration_type) {
              regType = option.name
            }
          }
        }
      }
      return regType
    }
    return 'Default'
  }

  getMemberTotal (member: Record<string, any>): number {
    return member?.participant_data?.cur_reg_fee
  }
}
